.title {
  margin-right: 10px;
}

.collapseIcon {
  transition: transform 0.2s ease-in-out;
}
.collapseIcon.collapsed {
  transform: rotate(-90deg);
}

.rightIcons {
  display: flex;
}
