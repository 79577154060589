@import '../../../assets/css/design/utilities/text';

.spaceNode {
  user-select: none;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 55px;
  line-height: 18px;
  padding: 0 8px 0 15px;

  &:hover {
    background: var(--item-hover-on-secondary-color);
  }

  &.selected {
    background: var(--item-selected-on-secondary-color);
  }

  .badges {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .name {
    @include text-ellipsis();
    min-width: 0;
  }
  .meta {
    @include text-ellipsis();
    min-width: 0;
  }

  /* devices that do not support hover should always display .top */
  @media (hover: hover) {
    .menu {
      display: none;
    }
  }
  &:hover {
    .menu {
      display: block;
    }
  }
}
