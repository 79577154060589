.collaborator-presence {
  &.online,
  &.away,
  &.offline {
    background: var(--online);
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
  }
  &.away {
    background: var(--away) !important;
    /*border: 1px solid var(--light-black);
    border-radius: 50%;*/
  }
  &.offline {
    background: var(--offline) !important;
    /*border: 1px solid var(--light-black);
    border-radius: 50%;*/
  }
}
