.channelIcon {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  border-radius: 50px;
  vertical-align: middle;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--channel-icon-background-color) !important;

  img {
    height: 30px;
  }
}

.transparent {
  background: transparent !important;
}
