.right-sidebar-button {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 100%;

  .floating.label {
    top: 8px;
    left: 35px;
  }

  &.bottom {
    margin-top: auto;
  }
}
