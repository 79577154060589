.meetingInvitation {
  display: flex;
  align-items: center;
  align-items: stretch;
  margin-bottom: 15px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
