@import '../../assets/css/design/utilities/_text.scss';

.first {
  padding-top: 20px;
}

.gutter {
  width: 60px;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.select-chat-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.chat-item {
  flex-grow: 1;
  position: relative;

  .starred {
    position: absolute;
    top: 5px;
    right: 10px;
  }
}

.space-chat-message {
  display: flex;
  flex-grow: 1;
  padding-left: 10px;

  &.first {
    padding-top: 20px;

    .chat-items-actions {
      top: -30px;
    }
  }

  .chat-items-tags {
    visibility: hidden;
  }

  &:hover {
    background-color: var(--color-surface-200);

    .chat-items-actions {
      display: flex !important;
    }
    .chat-items-tags {
      visibility: visible;
    }
  }

  &.starred {
    background-color: var(--star-highlight);
  }
}

.chat_message-content {
  width: 100%;
  transition: all 0.15s linear;
  align-items: center;
  padding: 6px 10px;
  border-radius: 5px;
  min-height: 36px;
}

.modal {
  .space-chat-message.first {
    &:hover {
      background: #fff;
    }
  }
}

.chat_message_header {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.space-chat-footer {
  padding-left: 40px;
  display: none;
}

.chat_person {
  font-weight: bold;
  margin-right: 6px;
  max-width: 140px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1400px) {
  .chat_person {
    max-width: 120px !important;
  }
}

@media only screen and (max-width: 1120px) {
  .chat_person {
    max-width: 100px !important;
  }
}

.forwarded {
  color: var(--grey);
  font-style: italic;
}

.chat_text {
  @include word-wrap();
  white-space: pre-line;
  padding: 0;

  &.quoted {
    border-left: 3px solid var(--light-grey);
    padding-left: 10px;
    margin-left: 10px;
    font-style: italic;
  }

  a {
    font-style: normal;
  }

  p {
    min-height: 10px;
    margin-bottom: 0px;
  }
}
