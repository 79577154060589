.preview-audio {
  display: flex;
  align-items: center;
  audio,
  &.placeholder {
    height: 40px;
    width: 100%;
  }

  &.error {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
