.section-header {
  border-bottom: 2px solid var(--color-surface-200);
  display: flex;
  align-items: center;
  height: 73px;
  padding: 0 0 0 15px;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: space-between;

  &.hide-border {
    border: none;
  }
}
