.sidebar-layout-header-right {
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 100%;

  > * {
    margin: 0 10px !important;
  }
  > *:last-child {
    margin-right: 5px !important;
  }
}
