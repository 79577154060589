.reminder {
  box-shadow: none !important;
  border: 1px #dddddd solid !important;
}

.note {
  color: #c8c8c8;
}

.completed {
  background: #b7b7b7 !important;
  color: white;

  .note {
    color: white;
  }
}

.future {
  background: var(--weteam-royal-blue) !important;
  color: white;
}

.actions {
  position: absolute;
  top: 5px;
  right: 5px;
  svg {
    width: 21px;
    height: 21px;
  }
}
