@import '../../../assets/css/design/utilities/text';

.badButton {
  text-decoration: none !important;
}
.feedbackContainers {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}
.feedbackContainer {
  text-align: center;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  flex: 1 1 0px;
  &:first-child {
    margin-right: 10px;
  }
  &:last-child {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 2px #00ae31;
  }
}

.emoji img {
  width: 100px;
  margin-bottom: 10px;
}

.goodButtonInfo {
  @include light-text();
  font-size: 0.8em;
  font-style: italic;
  margin-top: 6px;
}
