.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 1200px) {
  .small_hide {
    display: none !important;
  }
}

@media screen and (max-width: 1370px) {
  .medium_hide {
    display: none !important;
  }
}
