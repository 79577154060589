%gradient-standard {
  background-color: var(--gradiant-standard-color) !important;
  background-image: linear-gradient(
    to bottom right,
    var(--gradiant-standard-top),
    var(--gradiant-standard-bottom)
  ) !important;
}

%gradient-blue {
  background: var(--weteam-lighter-blue) !important;
  background-image: linear-gradient(
    to bottom right,
    #ddf8fd,
    #c0f2fc
  ) !important;
}

%gradient-dark-blue {
  background: var(--gradiant-dark-color);
  background: linear-gradient(
    45deg,
    var(--gradiant-dark-bottom) 0%,
    var(--gradiant-dark-top) 100%
  ) !important;
}

%spark-of-light {
  background: var(--spark-of-light-dark-color);
  background: radial-gradient(
    circle,
    var(--spark-of-light-dark-bottom) 0%,
    var(--spark-of-light-dark-top) 100%
  ) !important;
}
