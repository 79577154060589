/* TEXT */

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.highlight-text-color {
  background-color: var(--weteam-sky-blue);
}

.wrappable-text {
  @include word-wrap();
  white-space: pre-line;
  .emoji-mart-emoji img {
    margin: -4px 0;
  }
}

@mixin text-ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin light-text() {
  color: var(--secondary-font-color);
}

.light-text {
  color: var(--secondary-font-color);
}

.lighter-text {
  color: var(--tertiary-font-color);
}

.letter-spacing-wide {
  letter-spacing: 2px;
}

.uppercase {
  text-transform: uppercase;
}

.white {
  color: var(--primary-font-color);
}

.red {
  color: var(--our-red);
}

.align-right {
  text-align: right;
}

.text-align-center {
  text-align: center !important;
}
