.tooltip_icons {
  width: 20px;
  /* float: left; */
  display: inline-block;
  .corner_icon {
    right: -4px !important;
    bottom: 1px !important;
  }
}

.tooltip_corner_icon {
  right: -4px !important;
  bottom: 1px !important;
}
