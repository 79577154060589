.collapsiblePanel {
  flex-shrink: 0;
}
.container {
  border-radius: 5px;
  margin: 10px;
  padding: 10px 15px;
  background-color: var(--color-surface-200);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.audioPlayer {
  height: 40px;
  background-color: var(--color-surface-200);
  width: 100%;
}

.audio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
}

.title {
  margin-bottom: 10px;
  font-weight: bold;
}
