.arrowDown {
  flex-shrink: 0;
}

.menuItems {
  :global(.item) {
    display: flex !important;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
}
