.preview-video {
  video,
  img {
    height: 350px;
    width: 100%;
  }
  img {
    object-fit: scale-down;
    /* required so the child Loader is positioned in the centre */
    position: relative;
  }
  &.error {
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.placeholder_video {
  height: 150px;
  width: 100%;
  background-image: url('./video-placeholder.png');
  background-size: 200px 150px;
  background-position: center;
  background-repeat: no-repeat;
}
