.iconButtonGroup {
  display: flex;
  align-items: center;
  background: var(--color-surface-100);
  padding: 5px 10px;
  border-radius: 4px;
  svg:not(:last-child),
  span:not(:last-child) {
    margin-right: 20px;
  }
}

.border {
  border: 1px solid var(--color-surface-300);
}
