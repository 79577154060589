.color {
  margin-right: 7px;
  height: 24px;
  width: 24px;
  display: inline-block;
  border-radius: 5px;
  opacity: 0.4;
  transition: all linear 0.1s;
  cursor: pointer;
  &:hover,
  &:focus,
  &.selected {
    opacity: 1;
    transform: scale(1.1);
  }
  &.selected {
    box-shadow: 0 0 0 3px rgba(63, 170, 178, 0.6);
  }
}
