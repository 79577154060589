%isOver {
  background: var(--weteam-lighter-blue);
}

%canDrop {
  background: var(--weteam-lighter-blue) !important;
}

%isOver {
  background: var(--weteam-sky-blue) !important;
}

.canDrop {
  @extend %canDrop;
}
.isOver {
  @extend %isOver;
}
