.sortableList {
  list-style-type: none;
  padding: 0;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.sortableItem {
  display: flex;
  align-items: center;
}

.navButtonDrag {
  color: white;
  z-index: 1001;
}

.buttons {
  width: 100%;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
  overflow-x: hidden;
  user-select: none;
  margin-bottom: 10px;
}

.buttons::-webkit-scrollbar-thumb {
  background-color: var(--nav-bar-scroll-color);
}

.searchIcon {
  margin-top: 8px;
  width: 20px;
  path {
    fill: #c0c0c0;
  }
}

.archived {
  display: inline-block;
  margin: 10px 0px 10px 16px;
}

.cache-bust {
  display: none;
  padding: 10px;
}
