.context-menu-popup {
  padding: 0 !important;
}

.context-menu {
  .menu {
    border: none !important;
  }
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
}

.pulse {
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: pulse;
}
