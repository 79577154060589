.icon path,
.icon circle {
  fill: var(--we-team-icon-color);
}

.link:hover path,
.link:hover circle,
.active path {
  fill: var(--we-team-icon-hover-color);
}
