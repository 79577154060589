@import '../../assets/css/design/utilities/branding';
@import '../../assets/css/design/utilities/media-queries';

nav {
  --nav-bar-transition-duration: 0.1s;
  height: 100%;
}

.archived_spaces_tooltip_icon {
  margin-top: 4px;
}

.corner_right {
  font-size: 10px !important;
  right: -1px !important;
}

.settings_icon_switcher {
  font-size: 30px !important;
  line-height: 30px;
  opacity: 0.6 !important;

  &:hover {
    transition: all var(--nav-bar-transition-duration) linear;
    opacity: 1 !important;
  }
}

.nav-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 73px;
  font-size: 18px;
  font-weight: bold;
}

$nav-bar-width: 260px;

.nav-bar {
  .nav-bar-content {
    display: flex;
    flex-shrink: 0;
    width: $nav-bar-width;
    flex-direction: column;
  }

  &.open {
    width: $nav-bar-width;
    transition: width var(--nav-bar-transition-duration) linear;

    @include large-monitor {
      box-shadow: none;
    }
  }

  color: var(--white);
  transition: width var(--nav-bar-transition-duration) linear;
  display: flex;
  flex-shrink: 0;
  width: var(--nav-bar-width-collapsed);
  overflow: hidden;
  @extend %gradient-dark-blue;

  .app-nav-workspaces-title {
    transition: all var(--nav-bar-transition-duration) linear;
    transform: translateX(-70%);

    &.open {
      transform: translateX(0%);
      transition: all var(--nav-bar-transition-duration) linear;
    }
  }
}

.teamspace-buttons {
  height: 40px !important;
}

.add-teamspace-plus {
  margin-left: 10px;
}
