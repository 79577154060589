:root,
:root.light {
  --our-red: #ff000f;
  --black: #212529;
  --light-black: #30363b;
  --grey: #666666;
  --light-grey: #e8ebed;
  --very-light-grey: #f9f9f9;
  --star-highlight: var(--light-grey);
  --highlighted-item: #c1f2fb;

  --link-color: var(--weteam-royal-blue);

  --weteam-royal-blue: #00465c;
  --weteam-sky-blue: #00c5e7;
  --weteam-sky-blue-rgb: 0, 197, 231;
  --weteam-lighter-blue: #dafaff;
  --weteam-grey: #f4f4f4;
  --weteam-streetlight: #ffc700;
  --weteam-dark-green: #00701f;
  --weteam-wood-green: #00ae31;
  --weteam-apple-green: #95e932;
  --weteam-lighter-green: #d9f3e5;
  --weteam-bubblegum-pink: #ff2f7d;
  --weteam-neutral-lighter: #f5f5f5;

  --notification-badge-color: #d43017;

  --grey-600: #757575;
  --white: #ffffff;
  --skeleton: #d4d8dc;

  /* presence */
  --online: #88c540;
  --offline: #c4c4c4;
  --away: #f0fc43;
  --scrollbar-width: 12px;

  --chat-text-input-height: 53px;
  --chat-text-input-margin-bottom: 10px;

  --nav-bar-width-collapsed: 75px;

  --color-surface-100: #fff;
  --color-surface-200: #f5f5f5;
  --color-surface-300: #e8ebed;
  --color-surface-400: #d5d9dc;

  --primary-font-color: #1c1c1c;
  --secondary-font-color: #666666;
  --tertiary-font-color: #9e9e9e;
  --disabled-font-color: #e8ebed;

  --item-selected-on-secondary-color: var(--color-surface-400);
  --item-hover-on-secondary-color: var(--color-surface-400);

  --item-selected-on-primary-color: var(--color-surface-300);
  --item-hover-on-primary-color: var(--color-surface-300);

  --gradiant-standard-color: #e8ebed;
  --gradiant-standard-top: #f9fafa;
  --gradiant-standard-bottom: #e8ebed;

  --gradiant-dark-color: #00a7c4;
  --gradiant-dark-top: #00517e;
  --gradiant-dark-bottom: #00a7c4;

  --spark-of-light-dark-color: #00a7c4;
  --spark-of-light-dark-top: #00517e;
  --spark-of-light-dark-bottom: #00a7c4;

  --scrollbar-color: #d6d6d6;
  --we-team-icon-color: var(--weteam-royal-blue);
  --we-team-icon-hover-color: var(--weteam-sky-blue);

  --nav-bar-scroll-color: #80aac0;
  --nav-bar-button-background: #008bb3;
  --nav-bar-button-color: #f3fafc;
  --nav-bar-button-highlight: #01c2e4;

  --pricing-table-plan-box-box-shadow: rgba(0, 0, 0, 0.1);

  --rc-step-item-process: rgba(0, 0, 0, 0.65);
  --rc-steps-item-wait: var(--color-surface-100);

  --auth-header-bg-color: #fff;
  --auth-form-bg-color: #fff;

  --channel-icon-background-color: #e4e4e4;

  --tools-background-color: #036f96;
}

@media (prefers-color-scheme: dark) {
  :root:not(.light) {
    --link-color: var(--weteam-sky-blue);

    --primary-font-color: #fff;
    --secondary-font-color: #dadada;
    --tertiary-font-color: #c5c5c5;
    --disabled-font-color: #7d7d7d;

    --color-surface-100: #001014;
    --color-surface-200: #00242c;
    --color-surface-300: #003540;
    --color-surface-400: #00404d;
    /*--color-surface-500: #717171;
    --color-surface-600: #8b8b8b; */

    --star-highlight: var(--color-surface-300);
    --highlighted-item: var(--color-surface-400);
    --gradiant-standard-color: var(--color-surface-100);
    --gradiant-standard-top: var(--color-surface-100);
    --gradiant-standard-bottom: var(--color-surface-200);

    --gradiant-dark-color: var(--color-surface-400);
    --gradiant-dark-top: var(--color-surface-300);
    --gradiant-dark-bottom: var(--color-surface-300);

    --spark-of-light-dark-color: var(--color-surface-200);
    --spark-of-light-dark-top: var(--color-surface-100);
    --spark-of-light-dark-bottom: var(--color-surface-300);

    --scrollbar-color: #7a7a7a;
    --we-team-icon-color: #fff;
    --we-team-icon-hover-color: var(--weteam-sky-blue);

    --item-selected-on-secondary-color: var(--color-surface-400);
    --item-hover-on-secondary-color: var(--color-surface-400);

    --item-selected-on-primary-color: var(--color-surface-300);
    --item-hover-on-primary-color: var(--color-surface-300);

    --nav-bar-scroll-color: var(--scrollbar-color);
    --nav-bar-button-background: var(--color-surface-200);
    --nav-bar-button-color: var(--primary-font-color);
    --nav-bar-button-highlight: var(--color-surface-400);

    --pricing-table-plan-box-box-shadow: rgba(255, 255, 255, 0.1);

    --rc-step-item-process: var(--primary-font-color);
    --rc-steps-item-wait: var(--color-surface-200);

    --auth-header-bg-color: var(--color-surface-200);
    --auth-form-bg-color: var(--color-surface-100);

    --channel-icon-background-color: #008fac;

    --tools-background-color: var(--color-surface-200);

    .ui.segment {
      background-color: var(--color-surface-100) !important;
      color: var(--primary-font-color) !important;
      border-color: var(--color-surface-400) !important;
      box-shadow: none !important;
    }

    .ui.attached.header,
    .ui.modal > .header {
      background: var(--color-surface-100) !important;
      box-shadow: none !important;
      border-color: var(--color-surface-400) !important;
      color: var(--primary-font-color) !important;
    }

    .ui.header {
      color: var(--primary-font-color) !important;
    }
    .ui.modal,
    .ui.modal > .content,
    .ui.modal > .actions {
      background-color: var(--color-surface-100) !important;
    }

    .ui.label {
      color: var(--primary-font-color) !important;
      background-color: var(--color-surface-400);
    }

    .ui.dropdown .menu > .message:not(.ui) {
      color: var(--primary-font-color) !important;
    }

    .ui.form label {
      color: var(--primary-font-color) !important;
    }

    .ui.message:not(.error):not(.warning):not(.info) {
      background-color: var(--color-surface-300) !important;
      color: var(--primary-font-color) !important;
    }

    input,
    textarea,
    .ui.selection.dropdown,
    .ui.form select {
      background-color: var(--color-surface-100) !important;
      color: var(--primary-font-color) !important;
      border: 1px solid var(--color-surface-400) !important;
    }
    .ui.form .fields.error .field textarea,
    .ui.form .fields.error .field select,
    .ui.form .fields.error .field input,
    .ui.form .field.error textarea,
    .ui.form .field.error select,
    .ui.form .field.error input {
      border-color: #ff000f !important;
    }

    .ui.attached.segment {
      border-color: var(--color-surface-400) !important;
    }
    /* This was also affecting the red notification labels (dots)*/
    // .ui.label {
    //   background-color: var(--color-surface-100) !important;
    //   color: var(--primary-font-color) !important;
    //   border: 1px solid var(--color-surface-400) !important;
    // }
    .ui.button.basic,
    .ui.button.basic:hover {
      color: var(--primary-font-color) !important;
      background-color: transparent !important;
      box-shadow: 0px 0px 0px 1px var(--color-surface-400) inset !important;
    }
    .ui.button.basic:hover {
      background-color: var(--color-surface-400) !important;
    }
    .ui.basic.active.button {
      color: var(--primary-font-color) !important;
      background-color: var(--color-surface-400) !important;
    }
    .ui.basic.active.button:hover {
      color: var(--primary-font-color) !important;
      background-color: var(--color-surface-200) !important;
    }

    .ui.dropdown .menu,
    .ui.vertical.menu {
      background-color: var(--color-surface-200) !important;
      border-color: var(--weteam-sky-blue) !important;
      box-shadow: none !important;
    }

    .ui.dropdown .menu > .item {
      color: var(--primary-font-color) !important;
    }
    .ui.dropdown .menu .active.item {
      background-color: var(--color-surface-300) !important;
    }

    .ui.menu a.item:hover {
      background-color: var(--color-surface-300) !important;
    }
    .ui.menu .item {
      color: var(--tertiary-font-color) !important;
    }
    .ui.menu .active.item {
      color: var(--primary-font-color) !important;
      background-color: var(--color-surface-300) !important;
    }
    .ui.checkbox label,
    .ui.checkbox + label {
      color: var(--primary-font-color) !important;
    }
    .ui.progress > .label {
      color: var(--primary-font-color) !important;
    }
    .ui.table,
    .ui.table thead th {
      color: var(--primary-font-color) !important;
    }
    .ui.segments {
      border: 1px solid var(--color-surface-400) !important;
      box-shadow: none;
    }
    .ui.selection.dropdown .menu > .item {
      border-top: none !important;
    }
    .ui.selection.visible.dropdown > .text:not(.default) {
      color: var(--primary-font-color) !important;
    }
    .ui.attached.message {
      box-shadow: none !important;
    }

    .ui.popup {
      color: var(--primary-font-color) !important;
      box-shadow: none !important;
      border-color: #008fac !important;
    }

    .ui.popup,
    .ui.inverted.bottom.popup:before,
    .ui.inverted.right.center.popup:before,
    .ui.inverted.left.center.popup:before,
    .ui.inverted.top.popup:before {
      background: var(--color-surface-300) !important;
    }
    .ui.tabular.menu .active.item,
    .ui.tabular.menu .item:hover {
      background: var(--color-surface-100) !important;
      border-color: var(--color-surface-400) !important;
    }
    .ui.tabular.menu {
      border-color: var(--color-surface-400) !important;
    }
    /* .ui.divider:not(.vertical):not(.horizontal) {
    border-color: var(--color-surface-400) !important;
  } */
    .ui.hidden.divider {
      border-color: transparent !important;
    }
    .ui.divider {
      color: var(--primary-font-color) !important;
    }
    .ui.basic.blue.buttons .button,
    .ui.basic.blue.button {
      box-shadow: 0px 0px 0px 1px var(--weteam-sky-blue) inset !important;
      color: var(--weteam-sky-blue) !important;
    }

    /* DayPickerInput */

    .DayPickerInput-Overlay {
      background: var(--color-surface-100) !important;
    }

    .DayPicker-Day--today {
      background: var(--color-surface-300) !important;
      color: #fff !important;
    }

    .DayPicker-Day {
      color: #fff !important;
    }

    .DayPicker-Day:hover {
      background: var(--color-surface-400) !important;
    }

    .ui.divider:not(.vertical):not(.horizontal) {
      border-top: 1px solid var(--color-surface-400);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}
