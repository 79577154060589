.closeIcon {
  flex-shrink: 0;
  align-self: end;
  margin-bottom: 5px;
}

.form {
  margin-bottom: 10px;
  border-radius: 5px;
  border: 2px solid var(--color-surface-400);
  padding: 5px;
}

#input {
  background: transparent !important;
  border: none !important;
  display: block;
  width: 100%;
}

.createContainer {
  border-radius: 5px;
  margin: 3px 0px;
  padding: 5px 10px;
  border: 2px solid var(--color-surface-400);
}

.colorContainer {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

:global(.rcp-body) {
  padding: 5px;
}

.tag {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  cursor: move;
  user-select: none;
}

.draggedTag {
  /* so it shows when dragging */
  z-index: 2000;
}
