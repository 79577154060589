$mobile-width: 767px;
$tablet-width: 991px;
$large-monitor-width: 1200px;

@mixin large-monitor {
  @media (min-width: #{$large-monitor-width}) {
    @content;
  }
}

@mixin small-monitor {
  @media (min-width: #{$tablet-width}) and (max-width: #{$large-monitor-width}) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: #{$tablet-width}) {
    @content;
  }
}

%hide-mobile {
  @include mobile {
    display: none !important;
  }
}

.hide-mobile {
  @include mobile {
    display: none !important;
  }
}
