.banner {
  position: relative;
  z-index: 901;
  color: #fff;
  background: #004358;
  div.primary {
    position: relative;
    flex-shrink: 0;
    padding: 12px 18px;
    font-size: 1.1em;
    line-height: 1.5;
    text-align: center;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;
    transition: 0.1s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .banner-text {
      flex-grow: 1;
    }
    svg {
      flex-shrink: 0;
    }
  }
  .extended {
    position: absolute;
    background: #004358;
    width: 100%;
    padding: 2rem;
    a,
    h1 {
      color: white;
    }
  }
}
