.button {
  height: var(--chat-text-input-height);
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.container {
  margin: 10px 10px var(--chat-text-input-margin-bottom);
  flex-shrink: 0;
}
