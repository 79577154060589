.container {
  --defaultWidth: 350px; // Used for calculations
  position: fixed;
  width: var(--defaultWidth);
  padding: 0 10px 10px 10px;
  z-index: 9998;
  height: auto;
  top: 0px;
  bottom: auto;
  margin: 0 auto;
  left: 50%;
  margin-left: -(var(--defaultWidth) / 2);
}
