.tooltip_icons {
  width: 20px;
  /* float: left; */
  display: inline-block;
  .corner_icon {
    right: -4px !important;
    bottom: 1px !important;
  }
  .single_spaces_tooltip_icon {
    width: 22px;
    height: 22px;
    background-image: url('../../assets/images/icons/single_spaces.svg');
    background-size: 20px 20px;
    background-repeat: no-repeat;
  }
}
