@import './SpaceChat/SpaceChat.scss';

.chat-items-actions {
  display: none !important;
  position: absolute;
  right: 2px;
  z-index: $chatItemActionsZindex;
  top: -10px;
}

.chat-items-actions-info-icon {
  margin-bottom: -5px;
}
