.message {
  flex-shrink: 0;
  margin: 10px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    flex-shrink: 0;
    margin-left: 10px !important;
  }
}
