.rc-steps-item-process .rc-steps-item-icon {
  border-color: var(--weteam-royal-blue) !important;
  background: var(--weteam-royal-blue) !important;
}

.rc-steps-item-icon {
  border-color: var(--weteam-royal-blue) !important;
}

.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon {
  color: var(--weteam-royal-blue) !important;
}

.rc-steps-item-finish .rc-steps-item-title:after {
  background-color: var(--weteam-royal-blue) !important;
}
.rc-steps-item-process .rc-steps-item-title {
  color: var(--rc-step-item-process) !important;
}

.rc-steps-item-wait .rc-steps-item-title,
.rc-steps-item-finish .rc-steps-item-title {
  color: var(--rc-steps-item-wait-font) !important;
}
.rc-steps-item-wait .rc-steps-item-icon {
  background-color: var(--rc-steps-item-wait) !important;
  border-color: #ccc !important;
}

.rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon {
  color: var(--disabled-font-color) !important;
}
