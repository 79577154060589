.collapseIcon {
  transition: transform 0.2s ease-in-out;
}
.collapseIcon.collapsed {
  transform: rotate(-180deg);
}

.drawer {
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 25px;
  position: relative;
}
.drawer span {
  padding-left: 30px;
}
.drawerCollapsed {
  justify-content: start;
}

.bottomButtonContainer {
  margin-top: auto;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  background-color: transparent;
  padding-bottom: 10px;
}

.bottomButtonContainer::before {
  content: '';
  position: absolute;
  height: 100%;
  bottom: 5px;
  left: 5px;
  right: 5px;
  border-radius: 10px;
  background-color: var(--tools-background-color);
}

.bottomButtonContainerClosed::before {
  width: 65px;
}
