.scroll-to-bottom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  cursor: pointer;
}

.jump-to-present-button {
  background-color: rgba(116, 127, 141, 0.9);
  border-radius: 5px;
  bottom: 2px;
  color: white;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  z-index: 10;
  padding: 0px 10px;
  height: 40px;
  margin: 0 10px 0;
  justify-content: space-between;
}
