.navButton {
  color: var(--nav-bar-button-color);
  width: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  height: 60px;
  &:hover {
    background: var(--nav-bar-button-background);
  }
  &.noBackgroundOnHover:hover {
    background: initial;
  }
  &:hover,
  &.selected {
    cursor: pointer;
  }
  &.selected {
    background: var(--nav-bar-button-background);
    &:before {
      background: var(--nav-bar-button-highlight);
      height: 100%;
      transition: height 0.25s ease-in-out, margin-top 0.25s ease-in-out;
      content: ' ';
      position: absolute;
      width: 5px;
    }
  }
}
.navButtonIcon {
  width: var(--nav-bar-width-collapsed);
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}
