.messageSelectionBanner {
  align-items: center;
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: var(--color-surface-300);
  z-index: 11;
  border-radius: 5px;
  margin: 10px 20px 10px 10px;
  padding: 10px 15px;
}
.icon {
  margin-left: 20px;
}
.buttons {
  display: flex;
  align-items: center;
}
