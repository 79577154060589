.clickToAddTag {
  cursor: pointer;
}

.wrappableTag {
  margin-bottom: 2px !important;
}

.container {
  width: 400px !important;
  max-width: 400px !important;
  max-height: 500px !important;
  display: flex !important;
  flex-direction: column;
}

.availableTags {
  flex: 1 1 auto;
  overflow-y: auto;
}

.clickToAddTag {
  background: transparent;
  border: 3px solid #00c5e7 !important;
  color: #00c5e7 !important;
}

.availableTag {
  align-items: center;
  border-radius: 5px;
  display: flex;
  margin: 3px 0px;
  min-height: 30px;
  padding: 5px 10px;
  transition: all 200ms linear;
  cursor: pointer;
  &:hover {
    background: var(--item-hover-on-primary-color);
  }
}

.selectedTag {
  background: var(--item-hover-on-primary-color);
  margin-top: 10px;
  margin-bottom: 10px;
}
