.file-drop-info {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  text-align: center;
  z-index: 1000;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: rgba(var(--weteam-sky-blue-rgb), 0.75);
  color: white;
  &.overlay {
    position: absolute;
  }
}

.upload-icon {
  animation-duration: 1.2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: upload-pulse;
}

@keyframes upload-pulse {
  from {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0px);
  }
}
