.toast {
  display: flex;
  color: var(--white);
  background-color: var(--weteam-royal-blue);
  cursor: pointer;
  position: relative;
  width: 100%;
  margin: 10px 0 0;
  padding: 15px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  &.error {
    background-color: var(--our-red);
  }
}

.icon {
  width: 28px;
  text-align: center;
  display: inline-block;
}

.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 9999;
  background-color: var(--weteam-sky-blue);
  transform-origin: left;
  animation: trackProgress linear 1 forwards;
}

@keyframes trackProgress {
  0% {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(0);
  }
}
