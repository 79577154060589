.collaborator-avatar {
  user-select: none;
  display: inline-block;
  border-radius: 50%;
  margin: 0 2px;
  position: relative;
  cursor: initial;

  &.canClick {
    cursor: pointer;
  }

  &.canClick {
    cursor: pointer;
    > span {
      cursor: pointer;
    }
  }

  span {
    font-size: 15px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    background: var(--weteam-royal-blue);
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: initial;
  }

  img {
    border-radius: 50%;
  }

  &.online:after,
  &.offline:after,
  &.away:after {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 3px solid white;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  &.online:after {
    background-color: var(--online);
  }

  &.offline:after {
    background-color: var(--offline);
  }

  &.away:after {
    background-color: var(--away);
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.2;
    cursor: initial;
  }
}
