.recurly-hosted-field {
  background-color: white;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  box-sizing: border-box;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 8px 0 8px;
}

.recurly-hosted-field-focus {
  border: 1px solid #85b7d9;
}

.recurly-hosted-field-invalid {
  //border: 1px solid #E35256;
  background: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
}

.recurly-hosted-field-number {
  width: 12em;
}

.recurly-hosted-field-month,
.recurly-hosted-field-year,
.recurly-hosted-field-cvv {
  width: 4em;
}

.recurly-elements {
  border: 1px solid #c4c4c4;
  border-radius: 0.28571429rem;
  height: 45px;
}

@media (prefers-color-scheme: dark) {
  .recurly-elements {
    border: 1px solid var(--color-surface-400) !important;
  }
}

.recurly-elements-error {
  border: 1px solid red !important;
}

@media (prefers-color-scheme: light) {
  .recurly-elements-error {
    background: #fff7f8;
  }
}

.recurly-element-card {
  height: 40px;
}
