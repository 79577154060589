.content,
.actions {
  text-align: center !important;
}

.paymentForm {
  text-align: left;
}
.emoji {
  font-size: 64px;
}
