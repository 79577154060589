.element-out-of-view {
  display: flex;
  justify-content: center;
  background-color: var(--weteam-royal-blue);
  border-radius: 25px;
  color: #fff;
  pointer-events: auto;
  padding: 2px 10px;
  margin-left: 5px;
  svg {
    transform: scale(0.7);
    flex-shrink: 0;
  }
}
