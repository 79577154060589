.thumbnail {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 4px;

  .corner {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
