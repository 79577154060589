$padding: 16px 20px 0px;

.sidebar {
  background-color: var(--color-surface-200);
  // border-right: 1px solid #e7e7e7;
  display: flex;
  flex: 0 0 402px;
  padding: $padding;
}

.fullWidthSidebar {
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  right: 50px;
  background: white;
  z-index: 10;
  background-color: var(--color-surface-200);
  border-right: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
  padding: $padding;
}
