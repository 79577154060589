@import './SpaceChat.scss';

.divider {
  position: -webkit-sticky;
  position: sticky;
  z-index: $dividerZindex;
  top: 0;
  padding: 10px 10px 10px 18px;
  background: var(--color-surface-100);
  text-transform: none;

  span {
    bottom: 0;
    background: var(--color-surface-300) !important;
    border-radius: 50px;
    padding: 2px 10px 4px;
    text-transform: none;
    letter-spacing: 2px;
    color: var(--primary-font-color);
  }
}

.blue {
  z-index: $blueDividerZindex;

  span {
    background: var(--weteam-royal-blue) !important;
    color: white;
  }
}
