/*
  FLEX
*/

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex;
}

.flex,
.inline-flex {
  &.column {
    flex-direction: column;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.flex-row {
    flex-direction: row;
  }
  &.align-items-center,
  &.center {
    align-items: center;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-end,
  &.end {
    justify-content: flex-end;
  }
  &.align-items-flex-start {
    align-items: flex-start;
  }
  &.align-items-flex-end {
    align-items: flex-end;
  }
  &.justify-content-space-between,
  &.space-between {
    justify-content: space-between;
  }
  &.padded-20 {
    padding: 20px;
  }
  .align-self-center {
    align-self: center;
  }
  .align-self-flex-end {
    align-self: flex-end;
  }
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex_one {
  flex: 1;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.min-width-zero {
  min-width: 0;
}
