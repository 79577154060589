.preview-image {
  // height: 360px;
  min-height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  img {
    // height: 360px;
    max-width: 100%;
    object-fit: scale-down;
    cursor: zoom-in;
    /* required so the child Loader is positioned in the centre */
    position: relative;
  }

  &.error {
    // height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// .placeholder_image {
//   // height: 360px;
//   width: 200px;
//   background-image: url('./image-placeholder.png');
//   background-size: 200px 150px;
//   background-position: center;
//   background-repeat: no-repeat;
// }

// .placeholder-loader {
//   left: 165px !important;
// }
