.note {
  max-width: 400px;
}
.canClick {
  cursor: pointer;
}

.toggle {
  margin-left: 5px !important;
}
