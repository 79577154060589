$dividerZindex: 9;
$blueDividerZindex: $dividerZindex + 1;
$chatItemActionsZindex: $blueDividerZindex + 1;

.chat_header_name {
  display: flex;
  align-items: center;
}

.smile_icon {
  flex-shrink: 0;
}

.change_view_button {
  width: 22px;
  height: 22px;
  right: 18px;
  position: absolute;
}
