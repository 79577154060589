.starred-message {
  position: relative;

  .remove-star {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  /* devices that do not support hover should always display .top */
  @media (hover: hover) {
    .remove-star {
      display: none;
    }

    &:hover {
      .remove-star {
        display: inline;
      }
    }
  }
}
