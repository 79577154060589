@import './variables';
@import './utilities/_index';
@import './utilities/_media-queries';
@import '_app';
@import '_dragAndDrop';
@import '_modals';
@import '_nav_left';
@import '_rc-steps';
@import '_space_chat';
@import '_react_split_pane';

html {
  background: var(--color-surface-100);
  @extend %gradient-standard;
  background-attachment: fixed;
}

body {
  @extend %gradient-standard;
  background-attachment: fixed;
  overflow-x: auto;
  -webkit-font-smoothing: subpixel-antialiased;

  &.electron {
    overflow: auto;
  }
  color: var(--primary-font-color);
}

.ReactVirtualized__Grid,
.ReactVirtualized__List {
  overflow-y: scroll !important;
}

:focus {
  outline: none;
}

/*-------------- HEADER-------------*/

.full-screen-app {
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: var(--color-surface-100);
}

.full-screen-centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  //background: #fafafa;
  @extend %gradient-standard;

  .segment {
    max-width: 500px;
    padding: 20px 20px 30px 20px;
  }
}

.file_thumb_root {
  white-space: nowrap;
  overflow: hidden;
  width: 50px;
  height: 50px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.DateRangePicker_picker {
  z-index: 300 !important;
}

// blockquote {
// }
blockquote,
q {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  font-style: italic;
  border-left: 3px solid var(--light-grey);
  margin-top: 10px;
  margin-left: 10px;
  padding-left: 10px;
}

.scrollable-menu {
  white-space: nowrap;
  overflow-x: auto;
  width: 90vw;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scrollable-menu::-webkit-scrollbar {
  display: none;
}

.icon-link {
  color: var(--light-black);
}

em-emoji-picker {
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
  --shadow: none;
}
