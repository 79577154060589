@import '../../../assets/css/design/utilities/text';

.sidebarWrapper {
  width: 100%;
  background-color: var(--color-surface-200);
}
.title {
  font-size: 18px;
}
.subTitle {
  margin: 10px 0 10px;
  padding-left: 30px;
  padding-right: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.addNewItem {
  @include text-ellipsis();
  cursor: pointer;
  letter-spacing: 1px;
  margin: 10px 10px 0 16px;
  font-weight: bold;
  i {
    margin-right: 7px !important;
  }
}
