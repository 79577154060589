.beacon {
  width: 20px;
  height: 20px;
  background: var(--notification-badge-color);
  border: 4px solid #ffab9e;
  border-radius: 20px;
  display: inline-block;
  animation: pulse 2s infinite;
}

.active {
  background: var(--weteam-wood-green);
  border-color: var(--weteam-apple-green);
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
