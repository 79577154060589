.password_subcontainer {
  border: 1px solid var(--light-grey);
  background: var(--white);
  border-radius: 5px;
  padding: 10px 20px;
  max-width: 440px;
  .field {
    margin: 5px 0 !important;
    .input {
      height: 36px;
    }
  }
  .otixo-logo {
    background-position: center;
    margin: auto;
  }
}

.link_password_text {
  margin: 20px 0; //text-align: center;
}

.warning-title {
  text-align: center;
}
