.space-chat-header {
  .space-name {
    font-size: 18px;
  }
  .arrow-down {
    flex-shrink: 0;
  }

  .actions {
    display: flex;
    align-items: center;

    > * {
      margin: 0 8px;
    }
  }
}

.space-options-menu {
  .item {
    display: flex !important;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
}
