@import '../../assets/css/design/utilities/_branding';

.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10000;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  @extend %gradient-dark-blue;
  color: white;
}
