.initials-icon {
  user-select: none;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: #616161;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
  transition: all 0.1s linear;
  &.team-space {
    background-image: url('../../assets/images/icons/team-spaces-white.svg');
  }
  &.account {
    background-image: url('../../assets/images/icons/one_user_white.svg');
  }
}
