.typing-indicator-container {
  margin: 0 10px;
  min-height: 20px;
  flex-shrink: 0;
  font-size: 0.85em;
  display: flex;
  align-items: center;
}

.typing-indicator {
  margin-right: 5px;
  flex-shrink: 0;
  span {
    animation: spinner-ellipsis 1.4s infinite ease-in-out;
    background-color: var(--grey);
    border-radius: 3px;
    display: inline-block;
    height: 6px;
    margin-right: 2px;
    opacity: 0.3;
    width: 6px;
    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes spinner-ellipsis {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
