.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
}

.headerRight {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  > * {
    margin-left: 5px !important;
  }
}
