/*------------ NAV --------------*/

.sidebar-scroller {
  padding-bottom: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  line-height: 23px;
  flex-grow: 1;

  .title {
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 4px;
    margin: 10px 0 10px;

    &.with-margin-top {
      margin-top: 40px;
    }
  }

  a {
    max-width: 310px;
    padding-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;
    line-height: 28px;
  }
}

.name_connector {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ui.dimmer .ui.loader:after {
  border-color: var(--light-black) var(--light-grey) var(--light-grey);
}

.tree_item {
  user-select: none;
  cursor: pointer;
  display: flex;
  padding: 4px 5px 4px 0;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;

  .checkbox {
    margin: 0 6px 0 0;
  }

  .ui.loader:after,
  .ui.loader:before {
    margin: 0;
  }

  .folder_name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 30px;
  }

  .arrow_icon {
    margin: 2px 4px 0 0 !important;
    font-size: 16px !important;
    line-height: 0.7 !important;
  }

  &:hover {
    background: var(--item-hover-on-secondary-color);

    a {
      color: var(--light-black);
    }
  }

  &.selected {
    background: var(--item-selected-on-secondary-color);
    font-weight: bold;
  }

  &:active {
    background: var(--item-selected-on-secondary-color);
  }

  &:focus {
    background: var(--item-selected-on-secondary-color);
  }

  .loader {
    margin: auto 14px auto 0 !important;
  }
}

.cloudlist_icon {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin: 0 6px 0 0;
  vertical-align: middle;
  border: solid 1px var(--light-grey);
}
