textarea {
  width: 100%;
  /*height: 70px;*/
  padding: 10px 10px;
  box-sizing: border-box;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  background-color: #fff;
  font-size: 13px; // color: var(--grey);
  resize: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.validation-error {
  color: #c62828;
}
