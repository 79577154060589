.icon path,
.icon circle {
  fill: var(--we-team-icon-color);
}

.link {
  cursor: pointer;
  transition: transform 30ms linear;
}

.link:hover path,
.link:hover circle,
.active path {
  fill: var(--we-team-icon-hover-color);
}

.link:active {
  transform: scale(0.9);
}

.link:active path {
  fill: #00465c;
}

.white path,
.white:active path {
  fill: white;
}
.green path,
.green:active path {
  fill: var(--weteam-wood-green);
}
.blue path,
.blue:active path {
  fill: var(--weteam-royal-blue);
}
