/*
SCROLLING
*/

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

::-webkit-scrollbar {
  height: 12px;
  overflow: visible;
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-corner {
  background: rgba(255, 255, 255, 0.7);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  background-clip: padding-box;
  border: solid transparent;
  border-radius: 10px;
  /*border-width: 1px 1px 1px 1px;*/
  min-height: 28px;
  padding: 100px 0 0; //-webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  //box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
}

::-webkit-scrollbar-track {
  background-clip: padding-box; //background: rgba(255, 255, 255, 0.7);
  /*border: solid var(--very-light-grey);
    border-width: 0 0 0 1px;*/
}
