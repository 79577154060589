@import '../../assets/css/design/utilities/branding';

.container {
  height: 100vh;
  width: 100vw;
}
.content {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.segment {
  margin: 20px;
}
