.linkButton {
  color: var(--weteam-sky-blue);
  cursor: pointer;
  &:hover {
    color: #00a7c4;
  }
}
.dark {
  color: var(--weteam-royal-blue);
  &:hover {
    color: #00a7c4;
  }
}
.underlined {
  text-decoration: underline;
}
