.scroll-position-tracker-banner {
  &.bottom {
    bottom: 10px;
    transform: translateY(200%);
  }
  &.top {
    top: 0px;
    transform: translateY(-100%);
  }
  &.visible {
    transform: translateY(0%);
    opacity: 1;
    z-index: 101;
  }
  z-index: -1;
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: all 0.1s linear;
  cursor: pointer;
}
