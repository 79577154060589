@media only screen and (max-width: 1200px) {
  .enter-message-field {
    textarea {
      font-size: 14px !important;
    }
  }
}

.edit-container {
  padding: 10px 0;
}

.edit-actions {
  margin: 5px 0 0 10px;
}

.enter-message-field {
  .emoji-mart-emoji img {
    margin: 3px 0;
  }
  background-color: var(--color-surface-100);
  display: flex; // width: calc(100% - 50px);
  align-items: center;
  flex-grow: 1; //margin: 0 10px;
  position: relative; //padding: 6px 12px; //margin-bottom: 10px;
  margin: 0 10px 4px;
  @include word-wrap();

  &.bordered {
    border: 1px solid var(--color-surface-400);
    border-radius: 5px;
    padding: 5px 10px;
  }

  textarea {
    border-color: #fff !important;
    color: var(--light-grey) !important;
    margin: 0;
    padding: 5px;
    flex-grow: 1;
    opacity: 0.5;

    &:active,
    &:focus,
    &:visited {
      color: var(--light-black) !important;
      opacity: 1 !important;
    }
  }

  textarea#name {
    color: var(--light-black) !important;
  }
}

.smile-message-button {
  flex: 0 0 auto;
  margin: 0 10px 0 0;
  align-self: flex-end;
  padding: 3px 0 0 0;
}
