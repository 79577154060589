.transfer_window_title {
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  .ui.loader {
    margin-left: 5px;
  }
}

.transfer_label {
  text-align: left;
}

.transfer_window {
  // border-bottom: 1px solid var(--light-grey);
  flex: 1;
  padding: 10px 0px;
  margin: 10px;
  .transfer_window_progress {
    .ui.progress {
      margin-bottom: 2px;
      .bar {
        .progress {
          color: white;
        }
      }
    }
  }
}

.transfer_buttons_all {
  text-align: right;
  margin-top: 8px;
}
