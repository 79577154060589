@media only screen and (min-width: 1280px) {
  .reminderTarget {
    min-width: 200px;
  }
}

.reminderTarget {
  /* min-width: 200px; */
  max-width: 400px;
}
