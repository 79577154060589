.toggle {
  margin-left: 5px !important;
}

.resourceLinked > span {
  display: inline-block;
  margin-bottom: 10px;
}

@media (hover: hover) {
  .toggle {
    visibility: hidden;
  }

  .resourceLinked:hover .toggle {
    visibility: visible;
  }
}
